<template>
  <div class="home">
    <h1 class="d-none">Dashboard</h1>

    <div v-if="errors" class="alert alert-warning">{{ errors }}</div>

    <h2>ANCHOR ACCOUNT</h2>
    <div class="mb-5">
      <account-card v-for="account in main" :key="account.id" :account="account" :expanded="true" />
    </div>

    <h2>INCOME</h2>
    <div class="mb-5">
      <account-card v-for="account in incomes" :key="account.id" :account="account" />
    </div>

    <h2>EXPENSES</h2>
    <div class="d-flex mb-5 flex-wrap">
      <div class="d-flex mb-3 overflow-hidden">
        <apexchart
            ref="donut"
            width="400"
            type="donut"
            :options="chartOptions"
            :series="series"
            class="me-auto"
        ></apexchart>
      </div>
      <div>
        <account-card v-for="account in expenses" :key="account.id" :account="account" />
      </div>
    </div>



    <h2>ASSETS</h2>
    <div class="mb-5">
      <account-card v-for="account in assets" :key="account.id" :account="account" />
    </div>

  </div>
</template>

<script setup>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AccountCard from "@/components/AccountCard.vue";

const store = useStore()
const errors = ref('')
const eshopStore = computed(() => store.state.eshop.store)
//const storeName = computed(() => eshopStore.value && eshopStore.value.name || '-')
const waveStoreId = computed(() => store.getters['eshop/config']('WAVE_STORE_ID'))
const accounts = computed(() => {
  const a = eshopStore.value.accounts
  if (!a) return []
  return a.filter(a => parseFloat(a.balance) !== 0)
      //.sort((a, b) => b.type.value.localeCompare(a.type.value) ) // a.id === (eshopStore.value.config.WAVE_ANCHOR_ACCOUNT_ID || '') ? 0 :
})
const main = computed(() => accounts.value.filter(a => a.id === eshopStore.value.config.WAVE_ANCHOR_ACCOUNT_ID || ''))
const assets = computed(() => accounts.value.filter(a => a.type.value === 'ASSET'))
const incomes = computed(() => accounts.value.filter(a => a.type.value === 'INCOME'))
const expenses = computed(() => accounts.value.filter(a => a.type.value === 'EXPENSE'))

const chartOptions = computed(() => ({labels: expenses.value.map(e => e.name)}))
const series = computed(() => expenses.value.map(e => parseFloat(e.balance)))


function getWaveData() {
  if (!waveStoreId.value) {
    errors.value = 'You need to set up a Wave Account and add ID in Settings'
    return
  }
  store.dispatch('eshop/getWaveAccounts', 0)
      .catch(e => errors.value = 'getWaveAccounts error: ' + e.message)
}

getWaveData()


</script>

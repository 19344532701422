<template>
  <a :href="props.item.url" target="_blank" class="d-inline-block position-relative overflow-hidden">
    <img :src="props.item.img" class="m-1 rounded border" style="width:193px;height:193px;" :title="props.item.title" />
    <span class="item-price">{{ item.price }}</span>
  </a>
</template>

<script setup>
import {defineProps} from 'vue'
const props = defineProps(['item'])
</script>

<style lang="scss" scoped>
.item-price {
  position: absolute;
  top:20px; left:-35px;
  display:block;
  width: 150px;
  height: 30px;
  line-height: 30px;
  background: green;
  color: white;
  text-align: center;
  transform: rotate(-45deg);
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
</style>

<template>

    <form @submit.prevent="saveConfig" class="border-bottom pb-3 ">

      <label class="mb-2 lead">{{ dataKey }}</label>

        <transition mode="out-in">
          <loading-spinner v-if="loading" class="ms-auto" />
          <div v-else-if="show" class="d-flex align-content-center">
            <div class="flex-fill">
              <select v-if="dataKey.indexOf('ACCOUNT_ID') > 0" v-model="dataValue" class="form-control">
                <optgroup v-for="group in Object.keys(groups)" :key="group" :label="group">
                  <option
                    v-for="account in groups[group]"
                    :key="account.id"
                    :value="account.id"
                    :selected="account.id === dataValue"
                  >
                    {{ account.name }} (${{ account.balance }})
                  </option>
                </optgroup>
              </select>
              <select v-else-if="dataKey === 'WAVE_BUSINESS_ID'" v-model="dataValue" class="form-control">
                <option
                    v-for="business in businesses"
                    :key="business.id"
                    :value="business.id"
                    :selected="business.id === dataValue"
                >
                  {{ business.name }}
                </option>
              </select>
              <textarea v-else class="form-control me-3" v-model="dataValue"></textarea>
            </div>
            <div class="ms-auto d-inline-flex">
              <div>
                <button type="button" class="btn btn-link btn-sm" @click="show=false">Cancel</button>
              </div>
              <div>
                <button type="submit" class="ms-auto btn btn-light btn-outline-primary">Save</button>
              </div>
            </div>
          </div>
          <div v-else class="d-flex align-items-center">
            <div v-if="dataValue" class="text-info">{{ myAccount || '••••••••••••••••'}}</div>
            <button type="button" class="ms-auto btn btn-link btn-sm" @click="show = true">edit</button>
          </div>
        </transition>
    </form>

</template>

<script setup>
import {ref, defineProps, computed} from 'vue'
import { useStore } from 'vuex'
import {useAuth0} from "@auth0/auth0-vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
  dataKey: {
    type: String,
    required: true,
  }
})

const store = useStore()
const auth0 = useAuth0();
const storeConfig = computed(() => store.state.eshop.store.config || {})
const dataValue = ref(storeConfig.value[props.dataKey] || null)
const businesses = ref([])
const show = ref(false)
const loading = ref(false)

const accounts = computed(() => {
  const a = store.state.eshop.store.accounts
  return a || []
})

const groups = computed(() => {
  const a = accounts.value
  if (!a) return []
  const groups = {}
  a.forEach(account => {
    if (!groups[account.type.name]) groups[account.type.name] = []
    groups[account.type.name].push(account)
  })
  return groups
})

const myAccount = computed(() => {
  const a = accounts.value
  const b = businesses.value
  let filtered = []
  if (b.length) {
    filtered = b.filter(acc => acc.id === dataValue.value)
  } else if (a.length) {
    filtered = a.filter(bus => bus.id === dataValue.value)
  }
  return filtered.length > 0 ? filtered[0].name : shortName(dataValue.value)
})

function shortName(name) {
  if (!name) return '--'
  return name && name.length < 32 ? name : name.substring(0,24) + '•••••••' + name.substring(name.length-10)
}

if (props.dataKey === 'WAVE_BUSINESS_ID') {
  store.dispatch('eshop/getWaveBusinesses')
      .then(b => businesses.value = b)
}

function saveConfig () {
  loading.value = true
  store.dispatch('eshop/saveStoreConfig', {
    auth0,
    key: props.dataKey,
    val: dataValue.value
  })
      .then(() => {
        loading.value = false
        show.value = false
      })
      .catch(e => {
        alert(e.message)
        loading.value = false
      })

}

</script>

<template>
  <div>
    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else class="d-flex flex-wrap">
          <item-row v-for="item in filtered" :key="item.id" :item="item" />
      </div>
    </transition>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-light m-1" @click="perPage=val" v-for="val in [50,100,200]" :key="val">
        {{ val }}
      </button>
    </div>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import ItemRow from "./ItemRow.vue"
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

const loading = ref(false)
const items = ref([])
const perPage = ref(50)
const store = useStore()
const feedUrl = computed(() => store.getters['eshop/config']('INVENTORY_FEED'))
const filtered = computed(() => {
  return items.value.slice(0, perPage.value)
})

async function loadFeed() {
  loading.value = true
  try {
    const response = await fetch(feedUrl.value)
    items.value = await response.json()
  } catch (e) {
    console.error(e)
  }
  loading.value = false
}

loadFeed()
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <h1>Inventory</h1>

    <div v-if="errors" class="alert alert-warning">{{ errors }}</div>

    <div class="mb-5" v-if="website">
      <a :href="website" class="btn btn-primary mb-3">{{website}}</a>
      <item-feed />
    </div>
  </div>
</template>

<script setup>
import ItemFeed from "@/components/ItemFeed.vue";
import {computed} from "vue";
import {useStore} from "vuex"
const store = useStore()

const website = computed(() => store.getters["eshop/config"]('WEBSITE_URL'))
</script>

<style lang="scss" scoped>

</style>
